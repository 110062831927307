jQuery(document).ready(function($) {
	/* Mobile menu */
	$(".mobile-navigation-menu").mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	}, {
		fixedElements: {
			elemInsertMethod: "appendTo"
		}
	});

	$(".mobile-menu-button").click(function() {
		if ($(".mobile-navigation-menu").hasClass("mm-menu_opened")) {
			$(".mobile-navigation-menu").data("mmenu").close();
		}
		else {
			$(".mobile-navigation-menu").data("mmenu").open();
		}
	});

	/* FitVids */
	if ($("body").innerWidth() <= 767) {
		$(".inside-row-nested").fitVids({
			ignore: "nofit"
		});
		$(".paragraph-text").fitVids({
			ignore: "nofit"
		});
	}

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .text-block-20").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".nav-drop-wrap").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".nav-drop-wrap").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	/* Sticky Header */
	addStickyHeader();
	$(window).scroll(function() {
		addStickyHeader();
	});

	/* Webflow */
	$('.menu').on('click', function() {
		$('body').addClass('no-scroll');
	});

	$('.close').on('click', function() {
		$('body').removeClass('no-scroll');
	});

	Webflow.require('ix').init([{
		slug: "menu-block-animation",
		name: "Menu Block Animation",
		value: {
			style: {},
			triggers: [{
				type: "hover",
				selector: ".text-block",
				descend: true,
				stepsA: [{
					opacity: 1,
					transition: "opacity 500ms ease 0"
				}],
				stepsB: [{
					opacity: 0,
					transition: "opacity 500ms ease 0"
				}]
			}]
		}
	},
	{
		slug: "menu-price-animation",
		name: "Menu Price Animation",
		value: {
			style: {
				opacity: 0
			},
			triggers: [{
				type: "hover",
				stepsA: [{
					opacity: 1,
					transition: "opacity 500ms ease 0"
				}],
				stepsB: [{
					opacity: 1,
					transition: "opacity 500ms ease 0"
				}]
			}]
		}
	},
	{
		slug: "feature-image-hover",
		name: "Feature Image Hover",
		value: {
			style: {},
			triggers: [{
				type: "hover",
				selector: ".content-feature-image",
				descend: true,
				preserve3d: true,
				stepsA: [{
					transition: "transform 750ms ease 0",
					x: "0px",
					y: "0px",
					z: "0px",
					scaleX: 1.02,
					scaleY: 1.02,
					scaleZ: 1
				}],
				stepsB: [{
					transition: "transform 750ms ease 0",
					x: "0px",
					y: "0px",
					z: "0px",
					scaleX: 1,
					scaleY: 1,
					scaleZ: 1
				}]
			}]
		}
	}
	]);

	Webflow.require('ix2').init({
		events: {
			"e": {
				id: "e",
				name: "",
				animationType: "custom",
				eventTypeId: "MOUSE_CLICK",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-3",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-2"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "2453f17e-9358-9387-7a40-caa9626eb53f"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|34dde013-56c1-3eee-bb9f-fdf54e615696"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: null,
					scrollOffsetUnit: null,
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1601310219847
			},
			"e-23": {
				id: "e-23",
				name: "",
				animationType: "custom",
				eventTypeId: "PAGE_START",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-11",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-24"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "PAGE",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2"
				},
				targets: [{
					appliesTo: "PAGE",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: null,
					scrollOffsetUnit: null,
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1601405022575
			},
			"e-56": {
				id: "e-56",
				name: "",
				animationType: "custom",
				eventTypeId: "PAGE_SCROLL_DOWN",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-18",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-157"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "PAGE",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2"
				},
				targets: [{
					appliesTo: "PAGE",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: 80,
					scrollOffsetUnit: "%",
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1601566818804
			},
			"e-87": {
				id: "e-87",
				name: "",
				animationType: "custom",
				eventTypeId: "MOUSE_OVER",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-7",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-88"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|0a5f2a5d-cf3d-c1cf-dff0-c5126c8fd36c"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|0a5f2a5d-cf3d-c1cf-dff0-c5126c8fd36c"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: null,
					scrollOffsetUnit: null,
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1601926392514
			},
			"e-88": {
				id: "e-88",
				name: "",
				animationType: "custom",
				eventTypeId: "MOUSE_OUT",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-8",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-87"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|0a5f2a5d-cf3d-c1cf-dff0-c5126c8fd36c"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|0a5f2a5d-cf3d-c1cf-dff0-c5126c8fd36c"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: null,
					scrollOffsetUnit: null,
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1601926392514
			},
			"e-121": {
				id: "e-121",
				name: "",
				animationType: "custom",
				eventTypeId: "SCROLL_INTO_VIEW",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-33",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-122"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|9fbf9784-d991-52a0-f8d0-47f7acf64ce7"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|9fbf9784-d991-52a0-f8d0-47f7acf64ce7"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: 0,
					scrollOffsetUnit: "%",
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1599771774359
			},
			"e-122": {
				id: "e-122",
				name: "",
				animationType: "custom",
				eventTypeId: "SCROLL_OUT_OF_VIEW",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-34",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-121"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|9fbf9784-d991-52a0-f8d0-47f7acf64ce7"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|9fbf9784-d991-52a0-f8d0-47f7acf64ce7"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: 0,
					scrollOffsetUnit: "%",
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1599771774360
			},
			"e-150": {
				id: "e-150",
				name: "",
				animationType: "custom",
				eventTypeId: "PAGE_SCROLL_DOWN",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-37",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-149"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "PAGE",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2"
				},
				targets: [{
					appliesTo: "PAGE",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: 25,
					scrollOffsetUnit: "%",
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1602615188762
			},
			"e-157": {
				id: "e-157",
				name: "",
				animationType: "custom",
				eventTypeId: "MOUSE_OVER",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-24",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-158"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|2f55226d-3c82-0fec-97cf-c97df1b9f52d"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|2f55226d-3c82-0fec-97cf-c97df1b9f52d"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: null,
					scrollOffsetUnit: null,
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1604503410557
			},
			"e-158": {
				id: "e-158",
				name: "",
				animationType: "custom",
				eventTypeId: "MOUSE_OUT",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-25",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-157"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|2f55226d-3c82-0fec-97cf-c97df1b9f52d"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|2f55226d-3c82-0fec-97cf-c97df1b9f52d"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: null,
					scrollOffsetUnit: null,
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1604503410557
			},
			"e-159": {
				id: "e-159",
				name: "",
				animationType: "custom",
				eventTypeId: "MOUSE_OVER",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-24",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-160"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|ada0122f-db19-5ac0-a291-534ac418af89"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|ada0122f-db19-5ac0-a291-534ac418af89"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: null,
					scrollOffsetUnit: null,
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1604503413680
			},
			"e-160": {
				id: "e-160",
				name: "",
				animationType: "custom",
				eventTypeId: "MOUSE_OUT",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-25",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-159"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|ada0122f-db19-5ac0-a291-534ac418af89"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5f3bef4ab40b9ff6aedc72b2|ada0122f-db19-5ac0-a291-534ac418af89"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: null,
					scrollOffsetUnit: null,
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1604503413680
			},
			"e-165": {
				id: "e-165",
				name: "Unnamed interaction",
				animationType: "preset",
				eventTypeId: "SCROLL_INTO_VIEW",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-33",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-166"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fadb7811f35b464edba2fac|485861f9-c3b9-2c1c-11a4-c14850094c9b"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fadb7811f35b464edba2fac|485861f9-c3b9-2c1c-11a4-c14850094c9b"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: 0,
					scrollOffsetUnit: "%",
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1605276575871
			},
			"e-166": {
				id: "e-166",
				name: "Unnamed interaction",
				animationType: "preset",
				eventTypeId: "SCROLL_OUT_OF_VIEW",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-34",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-165"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fadb7811f35b464edba2fac|485861f9-c3b9-2c1c-11a4-c14850094c9b"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fadb7811f35b464edba2fac|485861f9-c3b9-2c1c-11a4-c14850094c9b"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: 0,
					scrollOffsetUnit: "%",
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1605276575871
			},
			"e-167": {
				id: "e-167",
				name: "Unnamed interaction",
				animationType: "custom",
				eventTypeId: "MOUSE_CLICK",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-43",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-168"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "2453f17e-9358-9387-7a40-caa9626eb52f"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "2453f17e-9358-9387-7a40-caa9626eb52f"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: null,
					scrollOffsetUnit: null,
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1605277179129
			},
			"e-169": {
				id: "e-169",
				name: "Unnamed interaction",
				animationType: "custom",
				eventTypeId: "MOUSE_CLICK",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-44",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-170"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "2453f17e-9358-9387-7a40-caa9626eb53d"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "2453f17e-9358-9387-7a40-caa9626eb53d"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: null,
					scrollOffsetUnit: null,
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1605277317518
			},
			"e-171": {
				id: "e-171",
				name: "Unnamed interaction",
				animationType: "preset",
				eventTypeId: "SCROLL_INTO_VIEW",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-33",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-172"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fb2c812ec1c4a81e64085f0|029ed0b5-91b2-b358-65c6-3d59fd42e674"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fb2c812ec1c4a81e64085f0|029ed0b5-91b2-b358-65c6-3d59fd42e674"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: 0,
					scrollOffsetUnit: "%",
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1605552206949
			},
			"e-172": {
				id: "e-172",
				name: "Unnamed interaction",
				animationType: "preset",
				eventTypeId: "SCROLL_OUT_OF_VIEW",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-34",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-171"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fb2c812ec1c4a81e64085f0|029ed0b5-91b2-b358-65c6-3d59fd42e674"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fb2c812ec1c4a81e64085f0|029ed0b5-91b2-b358-65c6-3d59fd42e674"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: 0,
					scrollOffsetUnit: "%",
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1605552206949
			},
			"e-173": {
				id: "e-173",
				name: "Unnamed interaction",
				animationType: "preset",
				eventTypeId: "SCROLL_INTO_VIEW",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-33",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-174"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fb32931ee745706335a4e23|57642892-d66a-9582-d64f-c271692fb3dd"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fb32931ee745706335a4e23|57642892-d66a-9582-d64f-c271692fb3dd"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: 0,
					scrollOffsetUnit: "%",
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1605578728825
			},
			"e-174": {
				id: "e-174",
				name: "Unnamed interaction",
				animationType: "preset",
				eventTypeId: "SCROLL_OUT_OF_VIEW",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-34",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-173"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fb32931ee745706335a4e23|57642892-d66a-9582-d64f-c271692fb3dd"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fb32931ee745706335a4e23|57642892-d66a-9582-d64f-c271692fb3dd"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: 0,
					scrollOffsetUnit: "%",
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1605578728825
			},
			"e-175": {
				id: "e-175",
				name: "Unnamed interaction",
				animationType: "preset",
				eventTypeId: "SCROLL_INTO_VIEW",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-33",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-176"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fb33008ce5676daaa3fe79e|104f4750-8048-47e8-2b6c-de1ae4d17c6d"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fb33008ce5676daaa3fe79e|104f4750-8048-47e8-2b6c-de1ae4d17c6d"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: 0,
					scrollOffsetUnit: "%",
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1605578763935
			},
			"e-176": {
				id: "e-176",
				name: "Unnamed interaction",
				animationType: "preset",
				eventTypeId: "SCROLL_OUT_OF_VIEW",
				action: {
					id: "",
					actionTypeId: "GENERAL_START_ACTION",
					config: {
						delay: 0,
						easing: "",
						duration: 0,
						actionListId: "a-34",
						affectedElements: {},
						playInReverse: false,
						autoStopEventId: "e-175"
					}
				},
				mediaQueries: ["main", "medium", "small", "tiny"],
				target: {
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fb33008ce5676daaa3fe79e|104f4750-8048-47e8-2b6c-de1ae4d17c6d"
				},
				targets: [{
					appliesTo: "ELEMENT",
					styleBlockIds: [],
					id: "5fb33008ce5676daaa3fe79e|104f4750-8048-47e8-2b6c-de1ae4d17c6d"
				}],
				config: {
					loop: false,
					playInReverse: false,
					scrollOffsetValue: 0,
					scrollOffsetUnit: "%",
					delay: null,
					direction: null,
					effectIn: null
				},
				createdOn: 1605578763935
			}
		},
		actionLists: {
			"a-3": {
				id: "a-3",
				title: "open menu",
				actionItemGroups: [{
					actionItems: [{
						id: "a-3-n-2",
						actionTypeId: "STYLE_SIZE",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							locked: false,
							target: {
								useEventTarget: "SIBLINGS",
								selector: ".nav",
								selectorGuids: ["3fdab7a0-cfc6-bd94-c121-fa949f3e5769"]
							},
							heightValue: 0,
							widthUnit: "PX",
							heightUnit: "PX"
						}
					}, {
						id: "a-3-n-3",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								useEventTarget: "SIBLINGS",
								selector: ".nav",
								selectorGuids: ["3fdab7a0-cfc6-bd94-c121-fa949f3e5769"]
							},
							value: 0,
							unit: ""
						}
					}, {
						id: "a-3-n-8",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								useEventTarget: true,
								id: "2453f17e-9358-9387-7a40-caa9626eb53f"
							},
							value: 1,
							unit: ""
						}
					}, {
						id: "a-3-n-10",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								selector: ".close-button",
								selectorGuids: ["53605741-90ab-5b55-642b-aba05bbf215e"]
							},
							value: 0,
							unit: ""
						}
					}]
				}, {
					actionItems: [{
						id: "a-3-n",
						actionTypeId: "GENERAL_DISPLAY",
						config: {
							delay: 0,
							easing: "",
							duration: 0,
							value: "flex",
							target: {
								useEventTarget: "SIBLINGS",
								selector: ".nav",
								selectorGuids: ["3fdab7a0-cfc6-bd94-c121-fa949f3e5769"]
							}
						}
					}, {
						id: "a-3-n-4",
						actionTypeId: "STYLE_SIZE",
						config: {
							delay: 0,
							easing: "",
							duration: 400,
							locked: false,
							target: {
								useEventTarget: "SIBLINGS",
								selector: ".nav",
								selectorGuids: ["3fdab7a0-cfc6-bd94-c121-fa949f3e5769"]
							},
							heightValue: 100,
							widthUnit: "PX",
							heightUnit: "%"
						}
					}, {
						id: "a-3-n-5",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							target: {
								useEventTarget: "SIBLINGS",
								selector: ".nav",
								selectorGuids: ["3fdab7a0-cfc6-bd94-c121-fa949f3e5769"]
							},
							value: 1,
							unit: ""
						}
					}, {
						id: "a-3-n-9",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 1000,
							target: {
								useEventTarget: true,
								id: "2453f17e-9358-9387-7a40-caa9626eb53f"
							},
							value: 0,
							unit: ""
						}
					}, {
						id: "a-3-n-11",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								selector: ".close-button",
								selectorGuids: ["53605741-90ab-5b55-642b-aba05bbf215e"]
							},
							value: 1,
							unit: ""
						}
					}, {
						id: "a-3-n-13",
						actionTypeId: "TRANSFORM_ROTATE",
						config: {
							delay: 0,
							easing: "",
							duration: 700,
							target: {
								selector: ".close-button",
								selectorGuids: ["53605741-90ab-5b55-642b-aba05bbf215e"]
							},
							zValue: 90,
							xUnit: "DEG",
							yUnit: "DEG",
							zUnit: "DEG"
						}
					}, {
						id: "a-3-n-12",
						actionTypeId: "TRANSFORM_ROTATE",
						config: {
							delay: 0,
							easing: "",
							duration: 1000,
							target: {
								useEventTarget: true,
								id: "2453f17e-9358-9387-7a40-caa9626eb53f"
							},
							zValue: 180,
							xUnit: "DEG",
							yUnit: "DEG",
							zUnit: "DEG"
						}
					}]
				}],
				createdOn: 1601310233425,
				useFirstGroupAsInitialState: true
			},
			"a-11": {
				id: "a-11",
				title: "Cover Image Animation",
				actionItemGroups: [{
					actionItems: [{
						id: "a-11-n",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|9e02dd26-b93f-db89-885f-feda244aa205"
							},
							value: 0,
							unit: ""
						}
					}, {
						id: "a-11-n-3",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|9e02dd26-b93f-db89-885f-feda244aa205"
							},
							xValue: 0,
							yValue: -100,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-11-n-5",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|9e02dd26-b93f-db89-885f-feda244aa207"
							},
							yValue: -100,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-11-n-7",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|9e02dd26-b93f-db89-885f-feda244aa207"
							},
							value: 0,
							unit: ""
						}
					}, {
						id: "a-11-n-9",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|e38b51bc-a88d-bb3d-df85-e2c54061fcfe"
							},
							value: 0,
							unit: ""
						}
					}]
				}, {
					actionItems: [{
						id: "a-11-n-4",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 2000,
							easing: "outQuad",
							duration: 800,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|9e02dd26-b93f-db89-885f-feda244aa205"
							},
							xValue: 0,
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-11-n-6",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 2000,
							easing: "outQuad",
							duration: 800,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|9e02dd26-b93f-db89-885f-feda244aa207"
							},
							xValue: 0,
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-11-n-8",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 2000,
							easing: "",
							duration: 1000,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|9e02dd26-b93f-db89-885f-feda244aa207"
							},
							value: 1,
							unit: ""
						}
					}, {
						id: "a-11-n-2",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 2000,
							easing: "",
							duration: 1000,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|9e02dd26-b93f-db89-885f-feda244aa205"
							},
							value: 1,
							unit: ""
						}
					}, {
						id: "a-11-n-10",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 2000,
							easing: "",
							duration: 1000,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|e38b51bc-a88d-bb3d-df85-e2c54061fcfe"
							},
							value: 1,
							unit: ""
						}
					}]
				}],
				createdOn: 1601403250755,
				useFirstGroupAsInitialState: true
			},
			"a-18": {
				id: "a-18",
				title: "Text Scrolled in View Two",
				actionItemGroups: [{
					actionItems: [{
						id: "a-18-n",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								selector: ".inner-content.reservations",
								selectorGuids: ["a5c54906-017a-7471-df9c-181f5bd54035", "b11fa527-81e6-2116-97a2-4fb0abf55de3"]
							},
							yValue: 40,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}]
				}, {
					actionItems: [{
						id: "a-18-n-2",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "outQuad",
							duration: 300,
							target: {
								selector: ".inner-content.reservations",
								selectorGuids: ["a5c54906-017a-7471-df9c-181f5bd54035", "b11fa527-81e6-2116-97a2-4fb0abf55de3"]
							},
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}]
				}],
				createdOn: 1601566830279,
				useFirstGroupAsInitialState: true
			},
			"a-7": {
				id: "a-7",
				title: "Arrow Button Hover Out",
				actionItemGroups: [{
					actionItems: [{
						id: "a-7-n-2",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								useEventTarget: "CHILDREN",
								selector: ".div-block-71",
								selectorGuids: ["5d244b24-e832-3539-c2c3-e1d3fd89f9fa"]
							},
							xValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-7-n-5",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								useEventTarget: "CHILDREN",
								selector: ".post-button",
								selectorGuids: ["5d244b24-e832-3539-c2c3-e1d3fd89f9f8"]
							},
							value: 1,
							unit: ""
						}
					}, {
						id: "a-7-n-7",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								useEventTarget: "CHILDREN",
								selector: ".div-block-71",
								selectorGuids: ["5d244b24-e832-3539-c2c3-e1d3fd89f9fa"]
							},
							value: 1,
							unit: ""
						}
					}, {
						id: "a-7-n-9",
						actionTypeId: "STYLE_TEXT_COLOR",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							globalSwatchId: "097c24ab",
							target: {
								useEventTarget: "CHILDREN",
								selector: ".post-button",
								selectorGuids: ["5d244b24-e832-3539-c2c3-e1d3fd89f9f8"]
							},
							rValue: 255,
							gValue: 255,
							bValue: 255,
							aValue: 1
						}
					}]
				}, {
					actionItems: [{
						id: "a-7-n-4",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							target: {
								useEventTarget: "CHILDREN",
								selector: ".div-block-71",
								selectorGuids: ["5d244b24-e832-3539-c2c3-e1d3fd89f9fa"]
							},
							xValue: 10,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-7-n-6",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							target: {
								useEventTarget: "CHILDREN",
								selector: ".post-button",
								selectorGuids: ["5d244b24-e832-3539-c2c3-e1d3fd89f9f8"]
							},
							value: 1,
							unit: ""
						}
					}, {
						id: "a-7-n-8",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							target: {
								useEventTarget: "CHILDREN",
								selector: ".div-block-71",
								selectorGuids: ["5d244b24-e832-3539-c2c3-e1d3fd89f9fa"]
							},
							value: 1,
							unit: ""
						}
					}, {
						id: "a-7-n-10",
						actionTypeId: "STYLE_TEXT_COLOR",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							globalSwatchId: "097c24ab",
							target: {
								useEventTarget: "CHILDREN",
								selector: ".post-button",
								selectorGuids: ["5d244b24-e832-3539-c2c3-e1d3fd89f9f8"]
							},
							rValue: 255,
							gValue: 255,
							bValue: 255,
							aValue: 1
						}
					}]
				}],
				createdOn: 1600894910235,
				useFirstGroupAsInitialState: true
			},
			"a-8": {
				id: "a-8",
				title: "Arrow Button Hover In",
				actionItemGroups: [{
					actionItems: [{
						id: "a-8-n-2",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 100,
							target: {
								useEventTarget: "CHILDREN",
								selector: ".div-block-71",
								selectorGuids: ["5d244b24-e832-3539-c2c3-e1d3fd89f9fa"]
							},
							xValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-8-n-4",
						actionTypeId: "STYLE_TEXT_COLOR",
						config: {
							delay: 0,
							easing: "",
							duration: 100,
							globalSwatchId: "097c24ab",
							target: {
								useEventTarget: "CHILDREN",
								selector: ".post-button",
								selectorGuids: ["5d244b24-e832-3539-c2c3-e1d3fd89f9f8"]
							},
							rValue: 255,
							gValue: 255,
							bValue: 255,
							aValue: 1
						}
					}]
				}],
				createdOn: 1600894996788,
				useFirstGroupAsInitialState: false
			},
			"a-33": {
				id: "a-33",
				title: "Black to Transparent",
				actionItemGroups: [{
					actionItems: [{
						id: "a-33-n",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "easeOut",
							duration: 150,
							target: {
								useEventTarget: "SIBLINGS",
								selector: ".navigation",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db79"]
							},
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-33-n-2",
						actionTypeId: "STYLE_BACKGROUND_COLOR",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								useEventTarget: "SIBLINGS",
								selector: ".navigation",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db79"]
							},
							rValue: 0,
							gValue: 0,
							bValue: 0,
							aValue: 0
						}
					}, {
						id: "a-33-n-3",
						actionTypeId: "STYLE_TEXT_COLOR",
						config: {
							delay: 0,
							easing: "easeOut",
							duration: 300,
							globalSwatchId: "097c24ab",
							target: {
								selector: ".nav-link-4.about",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db77", "7e16f4ee-07b4-7dce-e11a-cf4446f15311"]
							},
							rValue: 255,
							gValue: 255,
							bValue: 255,
							aValue: 1
						}
					}, {
						id: "a-33-n-4",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 150,
							target: {
								selector: ".logo-3",
								selectorGuids: ["b97c0b6f-eaa6-5e8e-6197-9b7f1bda383f"]
							},
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-33-n-5",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 150,
							target: {
								selector: ".nav-menu-2",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db76"]
							},
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-33-n-6",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 150,
							target: {
								useEventTarget: "SIBLINGS",
								selector: ".navigation",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db79"]
							},
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-33-n-9",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 150,
							target: {
								selector: ".search-icon.mobile",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db7a", "21f63469-52b0-c618-259f-e00080c3db86"]
							},
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-33-n-10",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 150,
							target: {
								selector: ".menu-button-4",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db73"]
							},
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-33-n-13",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 150,
							target: {
								selector: ".search-icon.mobile",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db7a", "21f63469-52b0-c618-259f-e00080c3db86"]
							},
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-33-n-14",
						actionTypeId: "STYLE_TEXT_COLOR",
						config: {
							delay: 0,
							easing: "easeOut",
							duration: 300,
							globalSwatchId: "097c24ab",
							target: {
								selector: ".nav-link-4.contact",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db77", "21f63469-52b0-c618-259f-e00080c3db82"]
							},
							rValue: 255,
							gValue: 255,
							bValue: 255,
							aValue: 1
						}
					}, {
						id: "a-33-n-15",
						actionTypeId: "STYLE_TEXT_COLOR",
						config: {
							delay: 0,
							easing: "easeOut",
							duration: 300,
							globalSwatchId: "097c24ab",
							target: {
								selector: ".nav-link-4.products",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db77", "21f63469-52b0-c618-259f-e00080c3db89"]
							},
							rValue: 255,
							gValue: 255,
							bValue: 255,
							aValue: 1
						}
					}, {
						id: "a-33-n-16",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 150,
							target: {
								selector: ".search-content",
								selectorGuids: ["be55ddcb-1279-3923-ed34-fdaa54e8b785"]
							},
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-33-n-17",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 150,
							target: {
								selector: ".icon-wrapper",
								selectorGuids: ["c995b361-19f6-ef01-a00f-6df89edf688b"]
							},
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}]
				}],
				createdOn: 1599770537368,
				useFirstGroupAsInitialState: false
			},
			"a-34": {
				id: "a-34",
				title: "Transparent to Black",
				actionItemGroups: [{
					actionItems: [{
						id: "a-34-n",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "easeOut",
							duration: 300,
							target: {
								useEventTarget: "SIBLINGS",
								selector: ".navigation",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db79"]
							},
							yValue: -50,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-34-n-2",
						actionTypeId: "STYLE_BACKGROUND_COLOR",
						config: {
							delay: 0,
							easing: "easeOut",
							duration: 300,
							globalSwatchId: "6a6894a7",
							target: {
								useEventTarget: "SIBLINGS",
								selector: ".navigation",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db79"]
							},
							rValue: 51,
							gValue: 51,
							bValue: 51,
							aValue: 1
						}
					}, {
						id: "a-34-n-3",
						actionTypeId: "STYLE_TEXT_COLOR",
						config: {
							delay: 0,
							easing: "easeOut",
							duration: 300,
							globalSwatchId: "097c24ab",
							target: {
								selector: ".nav-link-4.about",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db77", "7e16f4ee-07b4-7dce-e11a-cf4446f15311"]
							},
							rValue: 255,
							gValue: 255,
							bValue: 255,
							aValue: 1
						}
					}, {
						id: "a-34-n-5",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							target: {
								selector: ".nav-menu-2",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db76"]
							},
							yValue: 25,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-34-n-13",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							target: {
								selector: ".menu-button-4",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db73"]
							},
							yValue: 25,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-34-n-15",
						actionTypeId: "STYLE_TEXT_COLOR",
						config: {
							delay: 0,
							easing: "easeOut",
							duration: 300,
							globalSwatchId: "097c24ab",
							target: {
								selector: ".nav-link-4.contact",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db77", "21f63469-52b0-c618-259f-e00080c3db82"]
							},
							rValue: 255,
							gValue: 255,
							bValue: 255,
							aValue: 1
						}
					}, {
						id: "a-34-n-16",
						actionTypeId: "STYLE_TEXT_COLOR",
						config: {
							delay: 0,
							easing: "easeOut",
							duration: 300,
							globalSwatchId: "097c24ab",
							target: {
								selector: ".nav-link-4.products",
								selectorGuids: ["21f63469-52b0-c618-259f-e00080c3db77", "21f63469-52b0-c618-259f-e00080c3db89"]
							},
							rValue: 255,
							gValue: 255,
							bValue: 255,
							aValue: 1
						}
					}, {
						id: "a-34-n-17",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							target: {
								selector: ".logo-3",
								selectorGuids: ["b97c0b6f-eaa6-5e8e-6197-9b7f1bda383f"]
							},
							yValue: 25,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-34-n-18",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							target: {
								selector: ".search-content",
								selectorGuids: ["be55ddcb-1279-3923-ed34-fdaa54e8b785"]
							},
							yValue: 25,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-34-n-19",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							target: {
								selector: ".icon-wrapper",
								selectorGuids: ["c995b361-19f6-ef01-a00f-6df89edf688b"]
							},
							yValue: 25,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}]
				}],
				createdOn: 1599769199640,
				useFirstGroupAsInitialState: false
			},
			"a-37": {
				id: "a-37",
				title: "Scroll Down",
				actionItemGroups: [{
					actionItems: [{
						id: "a-37-n",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|810cc6ae-3193-d2eb-1d19-d70b503f2c90"
							},
							yValue: 30,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-37-n-2",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|d9939a28-e64c-ec72-6e0e-030c96610b56"
							},
							yValue: 30,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-37-n-3",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|c2c548e8-7ddc-b90c-e365-3e3cd73825ed"
							},
							yValue: 30,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}]
				}, {
					actionItems: [{
						id: "a-37-n-4",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|c2c548e8-7ddc-b90c-e365-3e3cd73825ed"
							},
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-37-n-5",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|d9939a28-e64c-ec72-6e0e-030c96610b56"
							},
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}, {
						id: "a-37-n-6",
						actionTypeId: "TRANSFORM_MOVE",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							target: {
								id: "5f3bef4ab40b9ff6aedc72b2|810cc6ae-3193-d2eb-1d19-d70b503f2c90"
							},
							yValue: 0,
							xUnit: "PX",
							yUnit: "PX",
							zUnit: "PX"
						}
					}]
				}],
				createdOn: 1602615200631,
				useFirstGroupAsInitialState: true
			},
			"a-24": {
				id: "a-24",
				title: "Content Box Hover Button In",
				actionItemGroups: [{
					actionItems: [{
						id: "a-24-n",
						actionTypeId: "STYLE_BACKGROUND_COLOR",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							globalSwatchId: "097c24ab",
							target: {
								useEventTarget: true,
								id: "5f3bef4ab40b9ff6aedc72b2|6d2cfb29-2d8d-bb84-c1c1-90b85d7c5475"
							},
							rValue: 255,
							gValue: 255,
							bValue: 255,
							aValue: 1
						}
					}, {
						id: "a-24-n-2",
						actionTypeId: "STYLE_TEXT_COLOR",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							globalSwatchId: "6a6894a7",
							target: {
								useEventTarget: "CHILDREN",
								selector: ".text-block-14",
								selectorGuids: ["6bfbbe07-1b52-be79-6bd5-ef74b824bb7c"]
							},
							rValue: 51,
							gValue: 51,
							bValue: 51,
							aValue: 1
						}
					}]
				}, {
					actionItems: [{
						id: "a-24-n-3",
						actionTypeId: "STYLE_TEXT_COLOR",
						config: {
							delay: 0,
							easing: "",
							duration: 400,
							globalSwatchId: "097c24ab",
							target: {
								useEventTarget: "CHILDREN",
								selector: ".text-block-14",
								selectorGuids: ["6bfbbe07-1b52-be79-6bd5-ef74b824bb7c"]
							},
							rValue: 255,
							gValue: 255,
							bValue: 255,
							aValue: 1
						}
					}, {
						id: "a-24-n-4",
						actionTypeId: "STYLE_BACKGROUND_COLOR",
						config: {
							delay: 0,
							easing: "",
							duration: 400,
							globalSwatchId: "2a43e202",
							target: {
								useEventTarget: true,
								id: "5f3bef4ab40b9ff6aedc72b2|6d2cfb29-2d8d-bb84-c1c1-90b85d7c5475"
							},
							rValue: 9,
							gValue: 21,
							bValue: 248,
							aValue: 1
						}
					}]
				}],
				createdOn: 1602526809760,
				useFirstGroupAsInitialState: true
			},
			"a-25": {
				id: "a-25",
				title: "Content Box Hover Button Out",
				actionItemGroups: [{
					actionItems: [{
						id: "a-25-n",
						actionTypeId: "STYLE_BACKGROUND_COLOR",
						config: {
							delay: 0,
							easing: "",
							duration: 200,
							globalSwatchId: "097c24ab",
							target: {
								useEventTarget: true,
								id: "5f3bef4ab40b9ff6aedc72b2|6d2cfb29-2d8d-bb84-c1c1-90b85d7c5475"
							},
							rValue: 255,
							gValue: 255,
							bValue: 255,
							aValue: 1
						}
					}, {
						id: "a-25-n-2",
						actionTypeId: "STYLE_TEXT_COLOR",
						config: {
							delay: 0,
							easing: "",
							duration: 200,
							globalSwatchId: "6a6894a7",
							target: {
								useEventTarget: "CHILDREN",
								selector: ".text-block-14",
								selectorGuids: ["6bfbbe07-1b52-be79-6bd5-ef74b824bb7c"]
							},
							rValue: 51,
							gValue: 51,
							bValue: 51,
							aValue: 1
						}
					}]
				}],
				createdOn: 1602526894713,
				useFirstGroupAsInitialState: false
			},
			"a-43": {
				id: "a-43",
				title: "Search Bar Open",
				actionItemGroups: [{
					actionItems: [{
						id: "a-43-n-2",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							target: {
								selector: ".search-content",
								selectorGuids: ["be55ddcb-1279-3923-ed34-fdaa54e8b785"]
							},
							value: 0,
							unit: ""
						}
					}, {
						id: "a-43-n",
						actionTypeId: "STYLE_SIZE",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							locked: false,
							target: {
								selector: ".search-content",
								selectorGuids: ["be55ddcb-1279-3923-ed34-fdaa54e8b785"]
							},
							widthValue: 0,
							widthUnit: "px",
							heightUnit: "PX"
						}
					}]
				}, {
					actionItems: [{
						id: "a-43-n-3",
						actionTypeId: "STYLE_SIZE",
						config: {
							delay: 0,
							easing: "",
							duration: 500,
							locked: false,
							target: {
								selector: ".search-content",
								selectorGuids: ["be55ddcb-1279-3923-ed34-fdaa54e8b785"]
							},
							widthUnit: "AUTO",
							heightUnit: "PX"
						}
					}, {
						id: "a-43-n-4",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 400,
							target: {
								selector: ".search-content",
								selectorGuids: ["be55ddcb-1279-3923-ed34-fdaa54e8b785"]
							},
							value: 1,
							unit: ""
						}
					}]
				}],
				createdOn: 1605277201573,
				useFirstGroupAsInitialState: true
			},
			"a-44": {
				id: "a-44",
				title: "Close Search Bar",
				actionItemGroups: [{
					actionItems: [{
						id: "a-44-n",
						actionTypeId: "STYLE_SIZE",
						config: {
							delay: 0,
							easing: "",
							duration: 400,
							locked: false,
							target: {
								useEventTarget: "PARENT",
								selector: ".search-content",
								selectorGuids: ["be55ddcb-1279-3923-ed34-fdaa54e8b785"]
							},
							widthValue: 0,
							widthUnit: "px",
							heightUnit: "PX"
						}
					}, {
						id: "a-44-n-2",
						actionTypeId: "STYLE_OPACITY",
						config: {
							delay: 0,
							easing: "",
							duration: 300,
							target: {
								useEventTarget: "PARENT",
								selector: ".search-content",
								selectorGuids: ["be55ddcb-1279-3923-ed34-fdaa54e8b785"]
							},
							value: 0,
							unit: ""
						}
					}]
				}],
				createdOn: 1605277323439,
				useFirstGroupAsInitialState: false
			}
		},
		site: {
			mediaQueries: [{
				key: "main",
				min: 992,
				max: 10000
			}, {
				key: "medium",
				min: 768,
				max: 991
			}, {
				key: "small",
				min: 480,
				max: 767
			}, {
				key: "tiny",
				min: 0,
				max: 479
			}]
		}
	});
});

function addStickyHeader() {
	if ($(window).scrollTop() > 1) {
		$('.navigation').addClass('sticky');
	}
	else {
		$('.navigation').removeClass('sticky');
	}
}
